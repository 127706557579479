import * as React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { IWidgetProps } from '../Widget/Widget.types';

import { classes } from './ProductFacetsEmptyMessage.st.css';

interface IProductFacetsEmptyMessageProps {
  onResetProductFacetsFilter?: IWidgetProps['onResetProductFacetsFilter'];
}

export const ProductFacetsEmptyMessage: React.FC<IProductFacetsEmptyMessageProps> = ({
  onResetProductFacetsFilter,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <p className={classes.message}>
        {t('searchResults.products.facets.noResultsFound.message')}
      </p>
      <p className={classes.resetContainer}>
        <button
          className={classes.resetButton}
          onClick={() => onResetProductFacetsFilter?.()}
        >
          {t('searchResults.products.facets.noResultsFound.resetButton.label')}
        </button>
      </p>
    </div>
  );
};
