import {
  ISearchDocumentType,
  SearchDocumentType,
} from '@wix/client-search-sdk';
import { ISearchSample } from '../../platform/searchResultsControllerStore';

export const applyWhitelist = ({
  documentTypes = [],
  searchSamples = [],
}: {
  documentTypes: ISearchDocumentType[];
  searchSamples: ISearchSample[];
}) => {
  const categoryWhitelist = Object.values(SearchDocumentType);

  return {
    documentTypes: documentTypes.filter(({ documentType }) =>
      categoryWhitelist.includes(documentType),
    ),
    searchSamples: searchSamples.filter(({ documentType }) =>
      categoryWhitelist.includes(documentType),
    ),
  };
};
