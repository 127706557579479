import * as React from 'react';

import {
  withEnvironment,
  InjectedEnvironmentProps,
} from '@wix/yoshi-flow-editor';

import {
  IListLayoutProps,
  IListLayoutItemProps,
  OnDocumentClick,
} from '../Layout.types';
import { ListLayoutItem } from './ListLayoutItem';

import { st, classes } from './ListLayout.st.css';

export interface ListLayoutProps
  extends IListLayoutProps,
    InjectedEnvironmentProps {}

class ListLayoutComp extends React.Component<ListLayoutProps> {
  static defaultProps: Partial<IListLayoutProps> = {
    renderItem: (props, key) => {
      return <ListLayoutItem key={key} {...props} />;
    },
  };

  render() {
    const { environment, renderItem, ...formatterProps } = this.props;
    const { isEditorX, isMobile } = environment;
    return (
      <ul
        className={st(classes.root, {
          mobileView: isMobile,
          fluid: isEditorX,
        })}
        data-hook="list-layout"
        ref={this.props.listRef}
        tabIndex={-1}
        role="region list"
        aria-label={this.props.label}
      >
        {this.props.items.map((item, index) => {
          const handleClick: React.MouseEventHandler<HTMLElement> = (e) =>
            this.props.onItemClick?.(e, item, index);

          const handleLinkClick: OnDocumentClick = (e, clickOrigin) =>
            this.props.onItemLinkClick?.(e, item, index, clickOrigin);

          const listItemProps: IListLayoutItemProps = {
            ...formatterProps,
            item,
            onClick: handleClick,
            onLinkClick: handleLinkClick,
          };

          return renderItem ? renderItem(listItemProps, index) : null;
        })}
      </ul>
    );
  }
}

export const ListLayout = withEnvironment(ListLayoutComp);
