import { SearchDocumentType } from '@wix/client-search-sdk';

export const demoTotals: Partial<Record<SearchDocumentType, number>> = {
  [SearchDocumentType.All]: 195,
  [SearchDocumentType.Blogs]: 58,
  [SearchDocumentType.Pages]: 73,
  [SearchDocumentType.Products]: 64,
};

export const listLayoutBreakPoint = 520;
