import React, { FC } from 'react';

import { classes } from './ProductFacet.st.css';

interface ProductFacetProps {
  title: string;
}

export const ProductFacet: FC<ProductFacetProps> = ({ title, children }) => {
  return (
    <>
      <div className={classes.subtitle}>{title}</div>
      {children}
    </>
  );
};
