import React from 'react';
import { get } from 'lodash';

import { Widget } from '../Widget/Widget';
import { IWidgetRootProps } from './WidgetRoot.types';
import { addHighlightStyle, HighlightStyle } from './addHighlightStyle';
import { applyWhitelist } from './applyWhitelist';

export default class WidgetRoot extends React.PureComponent<IWidgetRootProps> {
  componentDidMount() {
    this.props.host?.appLoadBI.loaded();
    // this.props.host?.registerToComponentDidLayout(this.props.onAppLoaded);
  }

  render() {
    const {
      apiErrorDetails,
      id,
      isDemoContent,
      language,
      currency,
      onDocumentClick,
      onDocumentTypeChange,
      onPageChange,
      onProductAddToCart,
      onProductFacetsFilterChange,
      onQuerySubmit,
      onResetProductFacetsFilter,
      productFacets,
      reportError,
      searchRequest,
      searchRequestStatus,
      searchResponse,
      searchResponseTotals,
      searchResultsAbsoluteUrl,
      settings,
      sortConfig,
      viewMode,
    } = this.props;

    console.groupCollapsed('WidgetRoot:render');
    console.log('props', this.props);
    console.log('settings', settings);
    console.groupEnd();

    const { documentTypes, searchSamples } = applyWhitelist({
      documentTypes: this.props.documentTypes,
      searchSamples: this.props.searchSamples,
    });

    const highlightStyle: HighlightStyle = get(
      this.props,
      [
        'host',
        'style',
        'styleParams',
        'fonts',
        'resultsHighlightFont',
        'style',
      ],
      {
        bold: false,
        italic: false,
        underline: false,
      },
    );

    searchResponse?.documents?.forEach((document) => {
      document.title = addHighlightStyle(document.title, highlightStyle);
      document.description = addHighlightStyle(
        document.description,
        highlightStyle,
      );
    });

    searchSamples?.forEach((sample) =>
      sample.documents.forEach((document) => {
        document.title = addHighlightStyle(document.title, highlightStyle);
        document.description = addHighlightStyle(
          document.description,
          highlightStyle,
        );
      }),
    );

    return (
      <div className={id}>
        <Widget
          apiErrorDetails={apiErrorDetails}
          documentTypes={documentTypes}
          isDemoContent={isDemoContent}
          language={language}
          currency={currency}
          onProductAddToCart={onProductAddToCart}
          onProductFacetsFilterChange={onProductFacetsFilterChange}
          onDocumentClick={onDocumentClick}
          onDocumentTypeChange={onDocumentTypeChange}
          onResetProductFacetsFilter={onResetProductFacetsFilter}
          onPageChange={onPageChange}
          onQuerySubmit={onQuerySubmit}
          reportError={reportError}
          searchRequest={searchRequest}
          searchRequestStatus={searchRequestStatus}
          searchResponse={searchResponse}
          searchResponseTotals={searchResponseTotals}
          searchResultsAbsoluteUrl={searchResultsAbsoluteUrl}
          searchSamples={searchSamples}
          categoryList={settings.categoryList}
          sortConfig={sortConfig}
          viewMode={viewMode}
          productFacets={productFacets}
        />
      </div>
    );
  }
}
