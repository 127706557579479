import decodeUriComponent from 'decode-uri-component';
import { DEFAULT_SEARCH_REQUEST } from '../defaultSearchRequest';

export function encodeQuery(query: string): string {
  return encodeURIComponent(query);
}

export function decodeQuery(query?: string): string {
  if (!query) {
    return DEFAULT_SEARCH_REQUEST.query;
  }

  // NOTE 1: The query argument here have been decoded with `decodeURI()` in the viewer already
  // (return value of `wixCodeApi.location.path`). So some encoded chars have been replaced
  // (like %20 -> ' ' for spaces), but others that are not handled by decodeURI have not
  // been replaced ('?' is still %3F for example). Unfortunately, one of the decoded chars is the
  // percentage char itself used for encoding (%25 -> '%'). So directly calling native
  // decodeURIComponent('%') at this point will throw malformed URL exception. As a workaround
  // we are using 'decode-uri-component' package which does handle this case gracefully.

  // NOTE 2: In Bolt `wixCodeApi.location.to()` argument will be URL encoded internally, while
  // in Thunderbolt it will not be encoded. For compatibility we are double decoding here.

  return decodeUriComponent(decodeUriComponent(query));
}
