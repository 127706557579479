import React from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

// This is a hack. It is needed because we have issues with experiments and SSR
// returns initial markup with all experiments set to false
// It causes multiple problems
// This hook helps to remount your component when needed
export const useRemount = () => {
  const [key, setKey] = React.useState(0);

  useIsomorphicLayoutEffect(() => {
    setKey(1);
  }, []);

  return key;
};
