import { encodeQuery, decodeQuery } from './query';
import { encodeDocumentType, decodeDocumentType } from './documentType';
import { encodePageNumber, decodePageNumber } from './page';
import { encodeSortBy, decodeSortBy } from './sortBy';
import { encodeProductPrice, decodeProductPrice } from './productPrice';
import {
  encodeProductCollections,
  decodeProductCollections,
} from './productCollections';

export * from './documentType';
export * from './page';
export * from './sortBy';
export * from './query';
export * from './productPrice';
export * from './productCollections';

export const encoders = {
  encodeQuery,
  encodeDocumentType,
  encodePageNumber,
  encodeSortBy,
  encodeProductPrice,
  encodeProductCollections,
};

export const decoders = {
  decodeQuery,
  decodeDocumentType,
  decodePageNumber,
  decodeSortBy,
  decodeProductPrice,
  decodeProductCollections,
};
