export function getResultsFoundMessageKey({
  isWithNumber,
  isWithQuery,
}: {
  isWithNumber: boolean;
  isWithQuery: boolean;
}): string {
  if (isWithNumber && isWithQuery) {
    return 'resultsFoundMessage.withNumberAndQuery';
  }

  return isWithNumber
    ? 'resultsFoundMessage.withNumber'
    : 'resultsFoundMessage.withQuery';
}

export function getResultsEmptyMessageKey({
  isWithNumber,
  isWithQuery,
}: {
  isWithNumber: boolean;
  isWithQuery: boolean;
}): string {
  if (!isWithNumber && !isWithQuery) {
    return 'resultsEmptyMessage.empty';
  }

  if (isWithNumber && isWithQuery) {
    return 'resultsEmptyMessage.withNumberAndQuery';
  }

  return isWithNumber
    ? 'resultsEmptyMessage.withNumber'
    : 'noResultsFoundMessage.withQuery';
}
