import { isEqual } from 'lodash';
import {
  ILocationSearchRequest,
  IEncodedLocationSearchRequest,
} from '../location.types';

import { encoders } from './serializers';
import { DEFAULT_SEARCH_REQUEST } from './defaultSearchRequest';
import { SearchDocumentType } from '@wix/client-search-sdk';

const isDocumentTypeValid = (doc: SearchDocumentType) =>
  Object.values(SearchDocumentType).includes(doc);

export function encodeSearchRequest({
  query,
  documentType,
  page,
  sortBy,
  minPrice,
  maxPrice,
  collections,
}: ILocationSearchRequest): IEncodedLocationSearchRequest {
  return {
    ...(query !== '' && { q: encoders.encodeQuery(query) }),
    ...(documentType &&
      isDocumentTypeValid(documentType) && {
        qc: encoders.encodeDocumentType(documentType),
      }),
    ...(page &&
      page !== DEFAULT_SEARCH_REQUEST.page && {
        page: encoders.encodePageNumber(page),
      }),
    ...(sortBy &&
      !isEqual(sortBy, DEFAULT_SEARCH_REQUEST.sortBy) && {
        sort: encoders.encodeSortBy(sortBy),
      }),
    ...((minPrice !== undefined || maxPrice !== undefined) && {
      price: encoders.encodeProductPrice({ minPrice, maxPrice }),
    }),
    ...(collections !== undefined &&
      !!collections.length && {
        collections: encoders.encodeProductCollections(collections),
      }),
  };
}
