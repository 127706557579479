import * as React from 'react';
import { loadImage } from './loadImage';

export enum ImageStatus {
  INITIAL,
  LOADING,
  LOADED,
  ERROR,
}

type IImageLoaderRenderProp = (props: {
  src?: string;
  status: ImageStatus;
}) => React.ReactNode;

interface IImageLoaderProps {
  src?: string;
  children: IImageLoaderRenderProp;
}

interface IImageLoaderState {
  status: ImageStatus;
  src: string;
}

export class ImageLoader extends React.Component<
  IImageLoaderProps,
  IImageLoaderState
> {
  private _isMounted: boolean = false;

  state: IImageLoaderState = {
    status: ImageStatus.INITIAL,
    src: '',
  };

  static getDerivedStateFromProps(
    props: IImageLoaderProps,
    state: IImageLoaderState,
  ) {
    if (props.src !== state.src) {
      return {
        status: props.src ? ImageStatus.INITIAL : ImageStatus.ERROR,
        src: props.src,
      };
    }

    return null;
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.state.status === ImageStatus.INITIAL) {
      void this.loadSrc();
    }
  }

  componentDidUpdate(
    prevProps: IImageLoaderProps,
    prevState: IImageLoaderState,
  ) {
    if (this.state.status === ImageStatus.INITIAL) {
      void this.loadSrc();
    }
  }

  async loadSrc() {
    this.setState({
      status: ImageStatus.LOADING,
    });

    try {
      await loadImage(this.state.src);

      if (this._isMounted) {
        this.setState({
          status: ImageStatus.LOADED,
        });
      }
    } catch (e) {
      if (this._isMounted) {
        this.setState({
          status: ImageStatus.ERROR,
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { src, children } = this.props;
    const { status } = this.state;

    return children({ src, status });
  }
}
