import * as React from 'react';

import { FloatingDropdown } from 'wix-ui-tpa/FloatingDropdown';

import { ISearchSortProps } from './SortControl.types';
import { SortOptionId } from '../../platform/sort/getSortOptions';

import { classes } from './SortControl.st.css';

const SortControlComponent: React.FC<ISearchSortProps> = ({
  onSortChange,
  selectedSortOption,
  selectLabel,
  showSort,
  sortOptions,
}) => {
  return showSort ? (
    <div className={classes.root}>
      <FloatingDropdown
        data-hook="search-sort-dropdown"
        placement="bottom-end"
        onChange={({ id }) => {
          onSortChange(
            typeof id === 'string' ? parseInt(id, 10) : SortOptionId.Default,
          );
        }}
        label={selectLabel}
        value={String(selectedSortOption)}
        options={sortOptions.map(({ id, value }) => ({
          id: String(id),
          isSelectable: true,
          value,
        }))}
      />
    </div>
  ) : null;
};

SortControlComponent.defaultProps = {
  onSortChange: () => {},
  selectedSortOption: 0,
  selectLabel: '',
  showSort: false,
  sortOptions: [],
};

export const SortControl = SortControlComponent;
