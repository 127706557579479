import { SearchDocumentType, ApiTypes } from '@wix/client-search-sdk';

import { NotTranslatedDropdownOption } from '../searchResultsControllerStore';
import { Experiments } from '@wix/yoshi-flow-editor';
import { Spec } from '../../../../../lib/specs';

export enum SortOptionId {
  Default = 0,
  ForumLastActivityDateDesc = 1,
  ForumCreatedDateDesc = 2,
  ForumTotalCommentsDesc = 3,
  ForumViewCountDesc = 4,
  ForumLikeCountDesc = 5,
  EventsStartDateAsc = 8,
  BlogPublishDateDesc = 9,
  ProductsPriceAsc = 10,
  ProductsPriceDesc = 11,
}

enum ForumOrderingField {
  CreatedDate = 'createdDate',
  LastActivityDate = 'lastActivityDate',
  LikeCount = 'likeCount',
  TotalComments = 'totalComments',
  ViewCount = 'viewCount',
}

enum EventsOrderingField {
  StartDate = 'startDate',
}

enum BlogOrderingField {
  PublishDate = 'publishDate',
}

enum ProductsOrderingField {
  DiscountedPriceNumeric = 'discountedPriceNumeric',
}

enum OrderingDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

const sortOptionConfig: {
  [key in SortOptionId]: {
    translation: string;
    sorters: ApiTypes.ISearchRequestSorters[];
  };
} = {
  [SortOptionId.Default]: {
    translation: 'searchResults.sort.options.default',
    sorters: [],
  },
  [SortOptionId.ForumLastActivityDateDesc]: {
    translation:
      'searchResults.sort.dropdownOptions.forum.lastActivityDate.desc',
    sorters: [
      {
        fieldName: ForumOrderingField.LastActivityDate,
        direction: OrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.ForumCreatedDateDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.createdDate.desc',
    sorters: [
      {
        fieldName: ForumOrderingField.CreatedDate,
        direction: OrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.ForumLikeCountDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.likeCount.desc',
    sorters: [
      {
        fieldName: ForumOrderingField.LikeCount,
        direction: OrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.ForumTotalCommentsDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.totalComments.desc',
    sorters: [
      {
        fieldName: ForumOrderingField.TotalComments,
        direction: OrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.ForumViewCountDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.viewCount.desc',
    sorters: [
      {
        fieldName: ForumOrderingField.ViewCount,
        direction: OrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.EventsStartDateAsc]: {
    translation: 'searchResults.sort.dropdownOptions.events1.startDate.desc',
    sorters: [
      {
        fieldName: EventsOrderingField.StartDate,
        direction: OrderingDirection.Ascending,
      },
    ],
  },
  [SortOptionId.BlogPublishDateDesc]: {
    translation: 'searchResults.sort.dropdownOptions.blog.publishDate.desc',
    sorters: [
      {
        fieldName: BlogOrderingField.PublishDate,
        direction: OrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.ProductsPriceAsc]: {
    translation: 'searchResults.sort.dropdownOptions.products.price.asc',
    sorters: [
      {
        fieldName: ProductsOrderingField.DiscountedPriceNumeric,
        direction: OrderingDirection.Ascending,
      },
    ],
  },
  [SortOptionId.ProductsPriceDesc]: {
    translation: 'searchResults.sort.dropdownOptions.products.price.desc',
    sorters: [
      {
        fieldName: ProductsOrderingField.DiscountedPriceNumeric,
        direction: OrderingDirection.Descending,
      },
    ],
  },
};

const forumSortOptions = [
  SortOptionId.Default,
  SortOptionId.ForumLastActivityDateDesc,
  SortOptionId.ForumCreatedDateDesc,
  SortOptionId.ForumTotalCommentsDesc,
  SortOptionId.ForumViewCountDesc,
  SortOptionId.ForumLikeCountDesc,
];

const eventsSortOptions = [
  SortOptionId.Default,
  SortOptionId.EventsStartDateAsc,
];

const blogSortOptions = [
  SortOptionId.Default,
  SortOptionId.BlogPublishDateDesc,
];

const productsSortOptions = [
  SortOptionId.Default,
  SortOptionId.ProductsPriceAsc,
  SortOptionId.ProductsPriceDesc,
];

const defaultSortOptions = [SortOptionId.Default];

function buildSortDropdownOptions(sortOptions: SortOptionId[]) {
  return sortOptions.map((id) => {
    return { id, valueKey: sortOptionConfig[id].translation };
  });
}

function getSortOptions(
  documentType?: SearchDocumentType,
): NotTranslatedDropdownOption[] {
  if (documentType === SearchDocumentType.Forums) {
    return buildSortDropdownOptions(forumSortOptions);
  }

  if (documentType === SearchDocumentType.Events) {
    return buildSortDropdownOptions(eventsSortOptions);
  }

  if (documentType === SearchDocumentType.Blogs) {
    return buildSortDropdownOptions(blogSortOptions);
  }

  if (documentType === SearchDocumentType.Products) {
    return buildSortDropdownOptions(productsSortOptions);
  }

  return buildSortDropdownOptions(defaultSortOptions);
}

function getSortRequest(sortOptionId: SortOptionId): ApiTypes.ISearchOrdering {
  return { ordering: sortOptionConfig[sortOptionId].sorters || [] };
}

function getDefaultOrdering(): ApiTypes.ISearchOrdering {
  return { ordering: [] };
}

function getDefaultSortOption() {
  return SortOptionId.Default;
}

function isOrderingSupported(
  documentType: SearchDocumentType,
  experiments: Experiments,
) {
  if (
    documentType === SearchDocumentType.Products &&
    experiments.enabled(Spec.ProductsSorting)
  ) {
    return true;
  }

  return [
    SearchDocumentType.Events,
    SearchDocumentType.Forums,
    SearchDocumentType.Blogs,
  ].includes(documentType);
}

export {
  getDefaultOrdering,
  getDefaultSortOption,
  getSortOptions,
  getSortRequest,
  isOrderingSupported,
};
