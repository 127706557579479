import React, { FC, useState } from 'react';
import { Range, RangeProps } from 'wix-ui-tpa/Range';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { IWidgetProps } from '../../Widget/Widget.types';
import { ProductFacet } from '../ProductFacet';

import { classes } from './ProductFacetsPrice.st.css';

interface ProductFacetsPriceProps {
  currency: string;
  minPrice: number;
  maxPrice: number;
  selectedMinPrice?: number;
  selectedMaxPrice?: number;
  onFacetsFilterChange: IWidgetProps['onProductFacetsFilterChange'];
}

export const ProductFacetsPrice: FC<ProductFacetsPriceProps> = ({
  currency,
  minPrice,
  maxPrice,
  selectedMinPrice = minPrice,
  selectedMaxPrice = maxPrice,
  onFacetsFilterChange,
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<RangeProps['value']>([
    selectedMinPrice,
    selectedMaxPrice,
  ]);

  const handleChange: RangeProps['onChange'] = (newValue) => {
    setValue(newValue);
  };

  const handleAfterChange: RangeProps['onAfterChange'] = ([
    newMinPrice,
    newMaxPrice,
  ]) => {
    if (newMinPrice !== minPrice || newMaxPrice !== maxPrice) {
      onFacetsFilterChange({
        minPrice: newMinPrice,
        maxPrice: newMaxPrice,
      });
    }
  };

  return (
    <ProductFacet title={t('searchResults.facets.priceTitle')}>
      <Range
        className={classes.root}
        min={Math.floor(minPrice)}
        max={Math.ceil(maxPrice)}
        format={{
          style: 'currency',
          currency,
        }}
        value={value}
        onChange={handleChange}
        onAfterChange={handleAfterChange}
      />
    </ProductFacet>
  );
};
