import { ISearchRequestSorters } from '@wix/client-search-sdk';
import { DEFAULT_SEARCH_REQUEST } from '../defaultSearchRequest';

export function encodeSortBy(sortBy: ISearchRequestSorters[]): string {
  return '';
}

export function decodeSortBy(raw: string | undefined): ISearchRequestSorters[] {
  return DEFAULT_SEARCH_REQUEST.sortBy;
}
