import * as React from 'react';

import { InputProps, Input } from 'wix-ui-core/dist/src/components/input';
import { InputWithOptions } from 'wix-ui-core/input-with-options';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';

import { ClearButton } from '../ClearButton';
import { SearchIcon } from '../SearchIcon';
import { ISearchBoxProps } from './SearchBox.types';
import { Spec } from '../../../../../lib/specs';

import { st, classes } from './SearchBox.st.css';

export const SearchBox: React.FC<ISearchBoxProps> = (props) => {
  let inputRef: Input = {} as Input;
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const [isInputFocused, setInputFocused] = React.useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.onSubmit(e);
    inputRef.blur();
  };

  const handleInputContainerClick = () => {
    inputRef.focus();
  };

  const handleInputRef = (ref: Input) => {
    inputRef = ref;
  };

  const getInputProps = (): InputProps & { ref(ref: Input): void } => {
    return {
      'aria-label': props.placeholder,
      type: 'search',
      value: props.value,
      onChange: props.onChange,
      placeholder: props.placeholder,
      name: 'search',
      autoComplete: 'off',
      ref: handleInputRef,
      prefix: <SearchIcon className={classes.icon} data-hook="search-icon" />,
      suffix: !!props.value && (
        <ClearButton
          onClick={props.onClear}
          data-hook="clear-button"
          aria-label="{clearLabel}"
        />
      ),
      onFocus: () => setInputFocused(true),
      onBlur: () => setInputFocused(false),
    };
  };

  return (
    <form
      action="#"
      className={st(classes.root, {
        inputFocused: isInputFocused,
        mobileView: isMobile,
        withDirection: experiments.enabled(Spec.WidgetRtl),
      })}
      data-hook="search-box-form"
      onSubmit={handleSubmit}
      role="search"
    >
      <div
        onClick={handleInputContainerClick}
        data-hook="input-with-options-container"
        className={classes.inputContainer}
      >
        <InputWithOptions
          className={classes.input}
          data-hook="input-with-options"
          id="search-box-in-widget-inputwithoptions"
          inputProps={getInputProps()}
          options={[]}
        />
      </div>
    </form>
  );
};

export default SearchBox;
