import { SearchDocumentType } from '@wix/client-search-sdk';

const encodingMap: Record<SearchDocumentType, string> = {
  [SearchDocumentType.All]: 'all',
  [SearchDocumentType.Pages]: 'pages',
  [SearchDocumentType.Products]: 'products',
  [SearchDocumentType.Blogs]: 'blogs',
  [SearchDocumentType.Forums]: 'forums',
  [SearchDocumentType.Bookings]: 'bookings',
  [SearchDocumentType.Events]: 'events',
  [SearchDocumentType.Programs]: 'programs',
};

const decodingMap = Object.entries(encodingMap).reduce<
  Record<string, SearchDocumentType>
>(
  (result, [documentType, encodedValue]) => ({
    ...result,
    [encodedValue]: documentType as SearchDocumentType,
  }),
  {},
);

export function encodeDocumentType(documentType: SearchDocumentType): string {
  return encodingMap[documentType];
}

export function decodeDocumentType(raw: string): SearchDocumentType {
  return decodingMap[raw];
}
