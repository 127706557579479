import { ISearchRequestPageNumber } from '@wix/client-search-sdk';
import { DEFAULT_SEARCH_REQUEST } from '../defaultSearchRequest';

export function encodePageNumber(page: ISearchRequestPageNumber): string {
  return page.toString();
}

export function decodePageNumber(
  raw: string | undefined,
): ISearchRequestPageNumber {
  if (!raw) {
    return DEFAULT_SEARCH_REQUEST.page;
  }
  const decoded = parseInt(raw, 10);
  return decoded > 0 ? decoded : DEFAULT_SEARCH_REQUEST.page;
}
